<template>
	<div class="lg:hidden my-6 mx-4 flex items-center justify-between md:mx-16" v-if="showControls">
		<NuxtLink to="/">
			<img src="/images/logo.svg" alt="logo" />
		</NuxtLink>
		<button @click="() => $emit('onToggle', true)">
			<Menu class="text-white w-8 h-8" />
		</button>
	</div>
	<div v-if="isOpen" id="modal" class="fixed w-screen h-screen top-0 left-0 bg-[#050607] z-30 pt-6 px-4 z-50">
		<div class="flex items-center justify-between pb-8">
			<NuxtLink to="/">
				<img src="/images/logo.svg" alt="logo" />
			</NuxtLink>

			<button @click="() => $emit('onToggle', false)">
				<X class="text-white w-9 h-9" />
			</button>
		</div>
		<template v-if="!user">
			<Button href="/login" class="w-full mb-6 px-14" title="Entrar"> Entrar </Button>
			<div class="bg-[#21262B] h-px w-full"></div>
			<div class="grid grid-cols-1 text-center">
				<NuxtLink
					:to="item?.href ?? '#'"
					@click="item?.action"
					v-for="item in dynamicItems as any[]"
					class="cursor-pointer mt-8 font-jakarta font-extrabold text-xl text-white"
				>
					{{ item.label }}
				</NuxtLink>
			</div>
		</template>
		<div class="text-white flex flex-col space-y-8" v-else>
			<div class="flex items-center gap-4">
				<img
					:src="user.avatar"
					:alt="`${user.personalInfo.firstName}'s avatar`"
					class="rounded-full w-[48px] h-[48px] object-cover"
				/>

				<p class="font-bold text-base">Olá, {{ user.displayName ?? user.personalInfo.firstName }}</p>
			</div>

			<div class="space-y-4 flex flex-col">
				<h1 class="font-jakarta font-extrabold text-xl">Minha conta</h1>
				<!-- <h2 class="font-jakarta text-base">Meus pedidos</h2> -->

				<NuxtLink :to="item.href" v-for="item in allowedMenuItems" class="font-jakarta text-base">
					{{ item.label }}
				</NuxtLink>
			</div>

			<div class="space-y-4 flex flex-col">
				<h1 class="font-jakarta font-extrabold text-xl">Outros</h1>

				<NuxtLink :to="item?.href" v-for="item in dynamicItems" class="font-jakarta text-base">
					{{ item.label }}
				</NuxtLink>
			</div>

			<div class="w-full h-px bg-[#21262B]"></div>

			<Button @click="signOut" class="bg-transparent text-xl gap-4" title="Sair da conta">
				<LogOut class="w-6 h-6 text-white" />
				Sair da conta
			</Button>
		</div>
	</div>
</template>

<script setup lang="ts">
import { LogOut, Menu, X } from 'lucide-vue-next'
import { loggedMenuItems } from '.'
import type { UserRole } from '~/types/models/user.interface'

const { user, signOut } = useUserStore()

const props = defineProps({
	isOpen: {
		type: Boolean,
		default: false,
	},
	showControls: {
		type: Boolean,
		default: true,
	},
	items: {
		type: Array,
	},
})

defineEmits(['onToggle'])

const dynamicItems = computed(() => {
	return (props.items || [
		{
			label: 'Loja',
			href: '/streamers',
		},
		{
			label: 'Como funciona',
			href: '/',
		},
	]) as { label: string; href?: string; action?: () => void }[]
})

const allowedMenuItems = computed(() => {
	return loggedMenuItems.filter(item => {
		return !item.allowedRoles || item.allowedRoles.includes(user?.role as UserRole)
	})
})
</script>
