<template>
	<div ref="headerRef" class="hidden lg:flex lg:gap-8 lg:h-20 z-20">
		<button @click="navigateTo('/')" class="bg-[#1E1E1E]/80 px-14 flex items-center justify-center rounded-[80px]">
			<img src="/images/logo.svg" alt="logo" />
		</button>
		<div class="bg-[#1E1E1E]/80 w-full flex items-center justify-between pr-2 pl-16 rounded-[80px]">
			<div class="flex items-center justify-center text-white font-bold space-x-6">
				<NuxtLink
					:to="item.href ?? '#'"
					@click="item.action"
					v-for="item in dynamicItems"
					class="cursor-pointer hover:text-[#CEF26F] transition-all duration-200"
				>
					{{ item.label }}
				</NuxtLink>
			</div>

			<slot name="button" v-if="$slots.button"></slot>
			<template v-else>
				<Button :href="ctaRedirect" class="w-fit px-14" v-if="!user" title="Entrar"> Entrar </Button>

				<div v-else class="flex items-center gap-2 mr-4">
					<img :src="user?.avatar" alt="User avatar" class="w-12 h-12 rounded-full" />
					<div class="font-bold flex flex-col">
						<p class="text-sm text-white">Olá, {{ user?.displayName ?? user?.personalInfo.firstName }}</p>

						<Popover @update:open="isLoggedMenuOpened = $event">
							<PopoverTrigger class="flex items-center">
								<p class="text-base text-white">Minha conta</p>
								<ChevronDownIcon
									class="w-5 h-5 text-white ml-1 duration-300 transform"
									:class="{
										'rotate-180': isLoggedMenuOpened,
									}"
								/>
							</PopoverTrigger>
							<PopoverContent
								class="rounded-2xl text-white bg-[#1E1E1E]/80 w-fit mt-6 mr-4 flex flex-col gap-4"
							>
								<div v-for="item in allowedMenuItems" :key="item.label" class="flex flex-col gap-4">
									<NuxtLink
										v-if="item.href"
										:to="item.href"
										class="flex items-center gap-2 text-white"
									>
										<component :is="item.icon" class="w-4 h-4" />
										<p class="text-sm hover:underline">{{ item.label }}</p>
									</NuxtLink>

									<button @click="signOut" class="flex items-center gap-2 text-white">
										<LogOut class="w-4 h-4" />
										<p class="text-sm hover:underline font-normal">Sair da conta</p>
									</button>
								</div>
							</PopoverContent>
						</Popover>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ChevronDownIcon, LogOut, Shield, ShoppingBagIcon, UserRound, X } from 'lucide-vue-next'
import Popover from '../popover/Popover.vue'
import PopoverTrigger from '../popover/PopoverTrigger.vue'
import PopoverContent from '../popover/PopoverContent.vue'
import { UserRole } from '~/types/models/user.interface'
import { loggedMenuItems } from '.'

const { user, signOut } = useUserStore()
const props = defineProps<{
	items?: {
		label: string
		href?: string
		action: () => void
	}[]
}>()
const isLoggedMenuOpened = ref(false)

const allowedMenuItems = computed(() => {
	return loggedMenuItems.filter(item => {
		return !item.allowedRoles || item.allowedRoles.includes(user?.role as UserRole)
	})
})

const headerRef = ref<HTMLElement | null>(null)

const dynamicItems = computed(() => {
	return (props.items || [
		{
			label: 'Loja',
			href: '/streamers',
		},
		{
			label: 'Como funciona',
			href: '/',
		},
	]) as typeof props.items
})

const ctaRedirect = computed(() => {
	return !!user ? getRoleBasedRedirect(user.role) : '/login'
})

defineExpose({
	ref: headerRef,
})
</script>
