import type { IBox } from '~/types/models/box.interface'
import { OrderShipmentStatus, OrderStatus } from '~/types/models/order.interface'
import { UserRole } from '~/types/models/user.interface'

export const getFullName = (user: any) => {
	return (
		user?.personalInfo.firstName[0].toUpperCase() +
		user?.personalInfo.firstName.substring(1) +
		' ' +
		user?.personalInfo.lastName[0].toUpperCase() +
		user?.personalInfo.lastName.substring(1)
	)
}

export const formatCurrency = (value: number) => {
	return value.toLocaleString('pt-br', {
		style: 'currency',
		currency: 'BRL',
	})
}

export const getBoxPrice = (box: IBox) => {
	return box.prices.find(price => price.currency === 'brl')?.price
}

export const downloadFile = async (fileUrl: string | Blob, fileName: string) => {
	const blob = typeof fileUrl === 'string' ? await fetch(fileUrl).then(r => r.blob()) : fileUrl

	const link = document.createElement('a')
	link.href = window.URL.createObjectURL(blob)
	link.download = fileName
	link.click()
	link.remove()
}

interface OrderStatusInfo {
	color: string
	label: string
	description: {
		[key in Exclude<UserRole, UserRole.ADMIN>]: string
	}
}

export const getOrderStatus = (status: OrderStatus | OrderShipmentStatus): OrderStatusInfo => {
	if (!status) {
		return {
			color: '#C99300',
			label: '...',
			description: {
				[UserRole.USER]: '...',
				[UserRole.STREAMER]: '...',
			},
		}
	}

	const statusMap: Record<typeof status, OrderStatusInfo> = {
		[OrderStatus.PENDING]: {
			color: '#C99300',
			label: 'Aguardando aprovação',
			description: {
				[UserRole.USER]:
					'Estamos aguardando a confirmação do seu pagamento. Assim que for aprovado, iniciaremos a preparação da sua caixa misteriosa. Você será notificado sobre qualquer atualização.',
				[UserRole.STREAMER]:
					'Pedido aguardando confirmação de pagamento do cliente.<br /> Você será notificado assim que o pagamento for aprovado.',
			},
		},
		[OrderStatus.PAID]: {
			color: '#00A86B',
			label: 'Pagamento confirmado',
			description: {
				[UserRole.USER]:
					'Ótimo! Seu pagamento foi confirmado com sucesso. Agora vamos iniciar o processo de fabricação da sua caixa misteriosa personalizada. Em breve você receberá novidades.',
				[UserRole.STREAMER]:
					'Pagamento confirmado! A caixa misteriosa do seu cliente entrará em produção.<br /> Você será atualizado sobre o progresso.',
			},
		},
		[OrderStatus.CANCELED]: {
			color: '#C91100',
			label: 'Cancelado',
			description: {
				[UserRole.USER]:
					'Este pedido foi cancelado. Se você não solicitou o cancelamento ou tem alguma dúvida, entre em contato com nosso suporte.',
				[UserRole.STREAMER]:
					'Pedido cancelado. Se precisar de mais informações, entre em contato com o suporte.',
			},
		},
		[OrderStatus.FAILED]: {
			color: '#C91100',
			label: 'Falhou',
			description: {
				[UserRole.USER]:
					'Ops! Houve um problema com seu pedido. Não se preocupe, nossa equipe já foi notificada. Por favor, entre em contato com o suporte.',
				[UserRole.STREAMER]:
					'Ocorreu um problema com este pedido. Nossa equipe está verificando. Entre em contato com o suporte para mais detalhes.',
			},
		},
		[OrderShipmentStatus.ASSEMBLING_BOX]: {
			color: '#C99300',
			label: 'Em fabricação',
			description: {
				[UserRole.USER]:
					'Sua caixa misteriosa está sendo preparada com todo carinho e atenção aos detalhes. Logo você receberá novidades!',
				[UserRole.STREAMER]:
					'A caixa misteriosa do seu cliente está em produção.<br /> Você será notificado quando estiver pronta para envio.',
			},
		},
		[OrderShipmentStatus.SHIPPING_BOX]: {
			color: '#C99300',
			label: 'Pronto para o envio',
			description: {
				[UserRole.USER]:
					'Sua caixa misteriosa está pronta e será enviada em breve! Em breve você receberá o código de rastreio.',
				[UserRole.STREAMER]:
					'A caixa do seu cliente está pronta e será enviada em breve.<br /> O código de rastreio será disponibilizado em seguida.',
			},
		},
		[OrderShipmentStatus.SHIPPED]: {
			color: '#00C92C',
			label: 'Enviado',
			description: {
				[UserRole.USER]:
					'Sua caixa misteriosa já está a caminho! Você pode acompanhar a entrega usando o código de rastreio fornecido.',
				[UserRole.STREAMER]:
					'A caixa foi enviada ao seu cliente!<br /> O código de rastreio está disponível para acompanhamento.',
			},
		},
	}

	return statusMap[status]
}

export const captureEvent = (event: string, properties = {}) => {
	const config = useRuntimeConfig()

	if (config.public.enablePosthog) {
		const { $posthog } = useNuxtApp()

		$posthog?.capture(event, properties)
	}
}
