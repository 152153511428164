<script setup lang="ts">
import { AccordionContent, type AccordionContentProps } from 'radix-vue'
import { twMerge } from 'tailwind-merge'
import { computed, type HTMLAttributes } from 'vue'

const props = defineProps<AccordionContentProps & { class?: HTMLAttributes['class'] }>()

const delegatedProps = computed(() => {
	const { class: _, ...delegated } = props

	return delegated
})
</script>

<template>
	<AccordionContent
		v-bind="delegatedProps"
		class="overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
	>
		<div :class="twMerge('pb-4 pt-0', props.class)">
			<slot />
		</div>
	</AccordionContent>
</template>
