<template>
	<button v-if="!href" v-bind="attrs" :class="mergedClass" :disabled="isLoading || isDisabled" :type="type">
		<slot v-if="!isLoading"></slot>
		<LoaderCircle v-if="isLoading" class="mx-auto animate-spin" />
	</button>
	<NuxtLink v-else :to="href" :class="mergedClass" v-bind="attrs">
		<slot></slot>
	</NuxtLink>
</template>

<script setup lang="ts">
import { LoaderCircle } from 'lucide-vue-next'
import { twMerge } from 'tailwind-merge'
import type { ButtonHTMLAttributes } from 'vue'

const props = defineProps<{
	variant?: 'primary' | 'black' | 'outline'
	href?: string
	loading?: boolean
}>()

const attrs = useAttrs()

const variantClass = {
	primary: 'bg-white font-bold text-lg hover:text-black hover:bg-[#CEF26F] hover:text-black',
	black: 'bg-black text-white hover:bg-[#CEF26F] hover:text-black',
	outline: 'bg-transparent border-[2px] border-[#E8ECEF]',
}[props.variant || 'primary']

const type = (attrs.type as ButtonHTMLAttributes['type']) || 'button'
const isLoading = computed(() => props.loading)
const isDisabled = computed(() => !!attrs.disabled)
const mergedClass = computed(() =>
	twMerge(
		'rounded-[60px] font-bold text-center flex items-center justify-center h-[60px] w-full disabled:opacity-40 disabled:cursor-not-allowed hover:duration-200 hover:transition-all disabled:pointer-events-none',
		variantClass,
		attrs.class as string
	)
)
</script>
