import { defineStore } from 'pinia'
import { UserRole, type IUser } from '~/types/models/user.interface'

export const getRoleBasedRedirect = (role?: UserRole): string => {
	const redirectMap: Record<UserRole, string> = {
		[UserRole.STREAMER]: '/profile/dashboard',
		[UserRole.ADMIN]: '/admin',
		[UserRole.USER]: '/profile/orders',
	}

	return role ? redirectMap[role] : '/streamers'
}

export const useUserStore = defineStore('user', {
	state: () =>
		({
			user: null,
		} as { user: IUser | null }),
	getters: {
		isLoggedIn: state => !!state.user,
	},
	actions: {
		login(user: IUser) {
			this.user = user
		},
		logout() {
			this.user = null
		},
		async updateAsset(asset: keyof IUser['assets'], value: string) {
			if (this.user) {
				await useApi(`/streamers/${this.user._id}/update-assets`, {
					method: 'POST',
					body: {
						[asset]: value,
					},
				})

				this.user.assets[asset] = value
			}
		},
		async updateAvatar(key: string) {
			if (this.user) {
				const { avatar } = await useApi<{ avatar: string }>(`/streamers/${this.user._id}/update-avatar`, {
					method: 'POST',
					body: {
						avatar: key,
					},
				})

				this.user.avatar = avatar
			}
		},
		async updateProfile({ displayName, slug }: Partial<IUser>) {
			if (this.user) {
				const data = await useApi<{
					displayName: string
					slug: string
				}>(`/streamers/${this.user._id}/update-profile`, {
					method: 'POST',
					body: {
						displayName,
						slug,
					},
				})

				this.user.displayName = data.displayName || this.user.displayName
				this.user.slug = data.slug || this.user.slug
			}
		},
		async signOut() {
			try {
				await useApi('/auth/logout', { method: 'POST' })

				this.logout()
				window.location.href = '/'
			} catch (error) {
				console.error(error)
			}
		},
	},
})
